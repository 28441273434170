@import "_variables";

html {
    font-size: 1.2px;
    scroll-behavior: smooth;
}

@media screen and (max-width: $screen-lg) {
    html {
        font-size: calc((100 * 100vw / 1440 / 100));
    }
}
@media screen and (max-width: $screen-md) {
    html {
        font-size: calc((100 * 100vw / 1023 / 100));
    }
}
@media screen and (max-width: $screen-sm) {
    html {
        font-size: calc((100 * 100vw / 375 / 100));
    }
}

body {
    display: block;
    width: 100%;
    font-size: 16rem;
    line-height: 24rem;
    margin: 0;
}
#root {
    display: block;
    height: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.w-100 {
    width: 100%;
}

b {
    font-weight: unset;
}
img {
    &:hover {
        cursor: default !important;
    }
}

$indents: 0, 4, 6, 8, 12, 16, 24, 32, 48, 54;
.gap {
    @each $gap in $indents {
        &-#{$gap} {
            gap: #{$gap}rem !important;
        }
    }
}
.m {
    @each $margin in $indents {
        &-#{$margin} {
            margin: #{$margin}rem !important;
        }
        &t-#{$margin} {
            margin-top: #{$margin}rem !important;
        }
        &b-#{$margin} {
            margin-bottom: #{$margin}rem !important;
        }
        &l-#{$margin} {
            margin-left: #{$margin}rem !important;
        }
        &r-#{$margin} {
            margin-right: #{$margin}rem !important;
        }
        &y-#{$margin} {
            margin-top: #{$margin}rem !important;
            margin-bottom: #{$margin}rem !important;
        }
        &x-#{$margin} {
            margin-right: #{$margin}rem !important;
            margin-left: #{$margin}rem !important;
        }
    }
}
.p {
    @each $padding in $indents {
        &-#{$padding} {
            padding: #{$padding}rem !important;
        }
        &t-#{$padding} {
            padding-top: #{$padding}rem !important;
        }
        &b-#{$padding} {
            padding-bottom: #{$padding}rem !important;
        }
        &l-#{$padding} {
            padding-left: #{$padding}rem !important;
        }
        &r-#{$padding} {
            padding-right: #{$padding}rem !important;
        }
        &y-#{$padding} {
            padding-top: #{$padding}rem !important;
            padding-bottom: #{$padding}rem !important;
        }
        &x-#{$padding} {
            padding-right: #{$padding}rem !important;
            padding-left: #{$padding}rem !important;
        }
    }
}
.hidden {
    overflow: hidden;
    height: 100%;
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    /* Other browsers */
    overscroll-behavior: none;
}
// .wrapper {
//     overflow: hidden;
//     height: 100vh;
//     position: relative;
// }
*,
*::before,
*::after {
    box-sizing: border-box;
}

.d-none {
    display: none !important;
}
.d-flex {
    display: flex !important;
}
.flex-column {
    flex-direction: column !important;
}
.flex-column-reverse {
    flex-direction: column-reverse !important;
}
.justify-space-between {
    justify-content: space-between;
}
.justify-content-center {
    justify-content: center;
}
.align-items-center {
    align-items: center;
}
a {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}
p {
    margin: 0;
}
strong {
    font-variation-settings: "wght" 700;
    font-weight: unset;
}

.main-screen {
    width: 100%;
    height: 100vh;
    // background: url(../public/img/main-bg.png) $gray-900 no-repeat;
    // background-size: cover;
    // padding: 40rem 0 72rem;
    position: relative;
    background-color: $gray-900;
    .container-center {
        height: 100%;
        justify-content: space-between;
    }
    .bg-img {
        position: absolute;
        width: 100%;
        aspect-ratio: 1920 / 900;
        bottom: 0;
        left: 0;
        z-index: 0;
    }
}
.container-center {
    max-width: 1224rem;
    padding: 0 64rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}
h1 {
    @include fnt(64, 72, white, 700);
    margin: 0;
    z-index: 5;
}
h2 {
    @include fnt(48, 52, white, 500);
    margin: 0 0 68rem;
}
b {
    color: $green;
}
.header {
    padding: 8rem 0;
    display: flex;
    align-items: center;
    gap: 82rem;
    margin-top: 40rem;
    &-logo {
        max-width: 135rem;
        height: auto;
        flex: none;
    }
    .header-line {
        width: 100%;
        height: 2rem;
        background-color: $green;
    }
    .menu {
        display: flex;
        gap: 12rem;
        z-index: 10;
        a {
            @include fnt(20, 28, white, 400);
            white-space: nowrap;
            transition: color 0.3s;
            z-index: 10;
            &:hover {
                color: $green;
                cursor: pointer;
            }
        }
    }
}
.partners {
    z-index: 5;
    &-title {
        @include fnt(32, 48, $green, 500);
        margin-bottom: 32rem;
        // margin-bottom: 72rem;
        z-index: 5;
    }
    &-carrousel {
        width: 100%;
        display: flex;
        gap: 16rem;
    }
}
.projects {
    &-block {
        width: 100%;
        background-color: $gray-600;
        padding: 126rem 0 166rem;
        position: relative;
        .frame1 {
            position: absolute;
            bottom: -224rem;
            left: 0;
        }
    }
    &-grid {
        display: grid;
        gap: 24rem;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        z-index: 5;
        .project {
            &-card {
                display: flex;
                flex-direction: column;
                background-color: $gray-950;
                padding: 32rem;
                border-radius: 24rem;
                &:hover{
                    cursor: pointer;
                }
                .flake {
                    max-width: 42rem;
                    width: 100%;
                    height: auto;
                    flex: auto;
                    align-self: flex-end;
                    margin-bottom: 26rem;
                }
            }
            &-more {
                @include fnt(24, 24, #3ec232, 500);
                text-align: end;
                margin-bottom: 26rem;
            }
            &-line {
                display: block;
                max-width: 72rem;
                width: 100%;
                height: 4rem;
                background-color: $green;
                flex: none;
                margin-bottom: 16rem;
            }
            &-text {
                @include fnt(24, 32, $gray-25, 400);
                margin-bottom: 8rem;
                height: 100%;
            }
            &-img {
                width: 100%;
                border-radius: 8rem;
                object-fit: cover;
                object-position: center;
                aspect-ratio: 536/244;
            }
        }
    }
}
.activities {
    &-block {
        width: 100%;
        // background-color: $gray-700;
        padding: 126rem 0;
        background: url(./img/Frame2.png) $gray-700 no-repeat;
        background-position: right bottom;
    }
    &-grid {
        display: grid;
        gap: 24rem;
        grid-template-columns: 1fr 1fr 1fr;
        width: 100%;
        grid-auto-rows: 1fr;
    }
}

.products {
    &-block {
        width: 100%;
        // background-color: $gray-700;
        padding: 126rem 0;
        background: $gray-800 no-repeat;
    }
    &-text {
        @include fnt(24, 32, $gray-25, 400);
        margin-bottom: 8rem;
        height: 100%;
        a {
            color: $green;
            white-space: nowrap;
            transition: color 0.3s;
            &:hover {
                cursor: pointer;
            }
        }
    }
}

.footer {
    width: 100%;
    background-color: $gray-950;
    padding: 96rem 0;
    &-form {
        max-width: 432rem;
    }
    .contacts-info {
        display: flex;
        flex-direction: column;
        border-right: 4rem solid #0d290b;
        width: 50%;
        padding-right: 16rem;
        .email {
            background-color: $green;
            padding: 8rem 16rem;
            border-radius: 16px;
            margin-bottom: 64rem;
            @include fnt(32, 48, #0d290b, 500);
            width: fit-content;
        }
        .contacts-text {
            @include fnt(14, 24, $gray-300, 400);
        }
        .site-inf {
            @include fnt(14, 20, $gray-400, 400);
            margin-top: 24rem;
        }
    }
    .footer-line {
        width: 4rem;
        background-color: #0d290b;
        height: auto;
        display: block;
    }
    &-title {
        @include fnt(24, 24, $green, 500);
        margin-bottom: 24rem;
    }
}
.form-error {
    @include fnt(14, 24, $red, 400);
    display: block;
}

.captcha-container {
    height: 30px;
    display: flex;
    gap: 10px;
    margin-top: 12rem;
}

.footer-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.form__item {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    width: 100%;
    max-width: 208rem;
    .form-field {
        padding: 24rem 12rem 8rem;
        border-radius: 8rem;
        background-color: $gray-600;
        @include fnt(20, 24, white, 400);
        outline: none;
        border: none;
    }
    .form-label {
        @include fnt(14, 16, $gray-300, 400);
        // margin-left: 12rem;
        position: absolute;
        z-index: 2;
        top: 8rem;
        left: 12rem;
    }
}
.form-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16rem;
}
input {
    &::placeholder {
        color: $gray-300;
        opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder {
        /* Edge 12 -18 */
        color: $gray-300;
    }
}
input:-internal-autofill-selected {
    background-color: $gray-600 !important;
}
._full {
    grid-column: 1 / 3;
    max-width: unset;
}
.save_button {
    @include fnt(20, 32, #0d290b, 500);
    border-radius: 8rem;
    padding: 12rem 16rem;
    background-color: $green;
    text-align: center;
    display: block;
    margin-top: 24rem;
    &:hover {
        cursor: pointer !important;
    }
}
.form-subtitle {
    @include fnt(14, 20, $gray-400, 400);
    margin-top: 12rem;
}
.form-success {
    max-width: 352rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.form-sended_title {
    @include fnt(32, 40, #46db39, 500);
    display: flex;
    align-items: center;
    &::before {
        content: "";
        background: url(./img/checkmark.svg) center no-repeat;
        display: block;
        flex: none;
        width: 32rem;
        height: 32rem;
        background-size: 32rem;
        margin-right: 8rem;
    }
}
.form-sended_subtitle {
    @include fnt(24, 32, $gray-400, 500);
    margin-top: 16rem;
}
.add-file__btn {
    @include fnt(16, 24, $green, 500);
    border: 1px solid $green;
    padding: 8rem 16rem;
    border-radius: 20rem;
    width: fit-content;
}
.file-name {
    @include fnt(16, 18, $gray-300, 500);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 16rem;
    &:before {
        content: "";
        display: block;
        flex: none;
        background: url(./img/file.svg) center no-repeat;
        width: 20rem;
        height: 20rem;
        background-size: 20rem;
        margin-right: 4rem;
    }
}
.partners-carrousel {
    gap: 16rem;
    margin-bottom: 72rem;
    overflow: auto;
    &:hover {
        cursor: default;
    }
    .partners-logo {
        // max-width: 232rem;
        width: 100%;
        max-width: 232rem;
        &:hover {
            cursor: default !important;
        }
    }
}
.partners-grid {
    gap: 16rem;
    margin-bottom: 72rem;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    &:hover {
        cursor: default;
    }
    .partners-logo {
        // max-width: 232rem;
        width: 100%;
        max-width: 232rem;
        &:hover {
            cursor: default !important;
        }
    }
}
.activity {
    &-item {
        padding: 24rem;
        border-radius: 16rem;
        background-color: $green;
    }
    &-text {
        @include fnt(16, 24, $gray-800, 500);
    }
    &-icon {
        width: 32rem;
        height: 32rem;
        flex: none;
        margin-right: 16rem;
    }
    &-line {
        display: flex;
        width: 100%;
        height: 2px;
        background-color: white;
    }
}
.footer-wrap {
    display: flex;
    justify-content: space-between;
}

.modal {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s;
    overflow: auto;
    z-index: 1000;
    &__content {
        background-color: $gray-800;
        border-radius: 24rem;
        padding: 24rem 32rem;
        margin: 48rem auto 24rem auto;
        min-width: 360rem;
        max-width: 808rem;
        width: fit-content;
        &._small {
            width: 420rem;
        }
        &._lightbox {
            padding: 0;
            min-width: unset;
            overflow: hidden;
            border-radius: 4rem;
            img {
                max-width: 100%;
            }
        }
    }
    &.active {
        opacity: 1;
        pointer-events: all;
        overscroll-behavior-y: contain;
        overflow-y: auto;
    }

    &__buttons-panel {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 8rem;
        align-items: center;
        margin: 24rem 0;
        * {
            justify-self: end;
        }
    }
    &-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24rem;
        z-index: 10;
    }
    &-more {
        @include fnt(20, 24, #3ec232, 500);
    }
    &-img {
        width: 100%;
        border-radius: 16rem;
        object-fit: cover;
        object-position: center;
        aspect-ratio: 724/342;
        margin-bottom: 24rem;
    }
    &-title {
        @include fnt(24, 32, white, 400);
        margin-bottom: 12rem;
    }
    &-text {
        @include fnt(20, 26, $gray-300, 400);
        img {
            width: 100%;
        }
    }
    &-close {
        width: 24rem;
        height: 24rem;
        flex: none;
        &:hover {
            cursor: pointer !important;
        }
    }
}
.modal.inventory .modal__content {
    min-width: unset;
}

.syncSaveHik {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    // display: grid;
    place-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s;
    overflow: auto;
    z-index: 1000;
    &__content {
        background-color: $gray-700;
        border-radius: 24rem;
        padding: 24rem 32rem;
        margin: 48rem auto 24rem auto;
        min-width: 550rem;
        max-width: 808rem;
        display: flex;
        flex-direction: column;
        width: fit-content;
        &._small {
            width: 420rem;
        }
        &._lightbox {
            padding: 0;
            min-width: unset;
            overflow: hidden;
            border-radius: 4rem;
            img {
                max-width: 100%;
            }
        }
    }
    &.active {
        opacity: 1;
        pointer-events: all;
        overscroll-behavior-y: contain;
        overflow-y: auto;
    }

    &__buttons-panel {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 8rem;
        align-items: center;
        margin: 24rem 0;
        * {
            justify-self: end;
        }
    }
    &-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24rem;
        z-index: 10;
    }
    &-img {
        width: 100%;
        border-radius: 16rem;
        object-fit: cover;
        object-position: center;
        aspect-ratio: 724/342;
        margin-bottom: 24rem;
    }
    &-title {
        @include fnt(24, 32, white, 400);
        margin-bottom: 12rem;
    }
    a {
        @include fnt(20, 26, #3ec232, 400);
    }
    &-text {
        @include fnt(20, 26, $gray-300, 400);
    }
    &-close {
        width: 24rem;
        height: 24rem;
        flex: none;
        &:hover {
            cursor: pointer !important;
        }
    }
}
.syncSaveHik.inventory .syncSaveHik__content {
    min-width: unset;
}

.accordion {
    background-color: $gray-800;
    @include fnt(20, 26, $gray-300, 400);
    border: solid 1px #ccc;
    margin: 10px 0;
    border-radius: 5px;
    overflow: hidden;

    .form-success {
        max-width: 352rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .form-sended_title {
        @include fnt(22, 28, #46db39, 500);
        display: flex;
        align-items: center;
        &::before {
            content: "";
            background: url(./img/checkmark.svg) center no-repeat;
            display: block;
            flex: none;
            width: 32rem;
            height: 32rem;
            background-size: 32rem;
            margin-right: 8rem;
        }
    }
    .form-sended_subtitle {
        @include fnt(18, 24, $gray-400, 500);
        margin-top: 16rem;
    }
}

.accordion-header {
    cursor: pointer;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.accordion-content {
    padding: 15px;
}

.accordion-form {
    @include fnt(16, 24, $gray-300, 400);
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 432rem;
    form {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .save_button {
        margin-top: 0;
    }
}

.links {
    display: flex;
    gap: 15px;
}

.button-group {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
}

.clear_button {
    @include fnt(20, 32, white, 500);
    border: 1px solid #e92a2a;
    border-radius: 8rem;
    padding: 12rem 16rem;
    background-color: #e92a2a;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    &:hover {
        cursor: pointer !important;
    }
}

// $screen-sm: 800px;
// $screen-md: 1023px;
// $screen-lg: 1439px;

@media screen and (max-width: $screen-lg) {
    .container-center {
        padding: 0 64rem;
    }
}
@media screen and (max-width: $screen-md) {
    .partners-grid {
        grid-template-columns: repeat(3, 1fr);
    }

    .container-center {
        padding: 0 32rem;
    }
    .bg-img {
        display: none;
    }
    .main-screen {
        width: 100%;
        height: 70vh;
        background: url(./img/mobile.png) $gray-900 no-repeat;
        background-size: cover;
    }
    .activities-grid {
        grid-template-columns: 1fr 1fr;
    }
}
@media screen and (max-width: $screen-sm) {

    .main-screen {
        height: 100vh;
    }

    .button-group {
        grid-template-columns: 1fr;
    }

    .links {
        flex-direction: column;
    }

    .syncSaveHik__content {
        min-width: 360rem;
    }

    .form-sended_title {
        @include fnt(28, 32, #46db39, 500);
        &::before {
            width: 28rem;
            height: 28rem;
            background-size: 28rem;
            margin-right: 8rem;
        }
    }
    .form-sended_subtitle {
        @include fnt(20, 24, $gray-400, 500);
        margin-top: 12rem;
    }
    .projects-grid .project-card {
        padding: 24rem;
    }
    .projects-grid .project-more {
        font-size: 16rem;
    }
    .frame1 {
        display: none;
    }
    .activities-block {
        background-image: none;
    }
    .header .menu a {
        font-size: 14rem;
    }
    .header {
        flex-direction: column;
        align-items: start;
        gap: 20rem;
    }

    .container-center {
        padding: 0 16rem;
    }
    .footer-wrap {
        flex-direction: column-reverse;
    }
    .footer .contacts-info {
        width: 100%;
        border-top: 4rem solid #0d290b;
        border-right: none;
        padding: 72rem 0 0;
        margin-top: 62rem;
    }
    .footer-form {
        max-width: unset;
    }
    .form__item {
        max-width: unset;
    }
    .activities-grid {
        grid-template-columns: 100%;
    }
    .projects-grid {
        grid-template-columns: 100%;
    }
    .projects-block .frame1 {
        width: 100%;
    }
    .header-line {
        display: none;
    }
    .header {
        justify-content: space-between;
        margin-top: 16rem;
    }
    h1 {
        font-size: 40rem;
        line-height: 48rem;
    }
    .activities-block {
        padding: 56rem 0;
    }
    .modal-title {
        font-size: 16rem;
        line-height: 24rem;
    }
    .modal__content {
        margin: 48rem auto 64rem auto;
    }
    .modal-text {
        font-size: 16rem;
        line-height: 24rem;
    }
    h2 {
        margin-bottom: 32rem;
        z-index: 10;
        font-size: 32rem;
        line-height: 40rem;
    }
    .header-logo {
        max-width: 106rem;
    }
    .projects-block {
        padding: 56rem 0 86rem;
    }
    .projects-grid .project-text {
        font-size: 16rem;
        line-height: 24rem;
    }
    // .project-card {
    //     padding: 28rem 24rem 24rem;
    // }
    .projects-grid .project-card .flake {
        max-width: 32rem;
    }
    .footer .contacts-info .email {
        font-size: 20rem;
        line-height: 32rem;
    }
    ._full {
        grid-column: unset;
    }
    .form-container {
        grid-template-columns: 100%;
    }
}
.disabled {
    background-color: $gray-400;
    pointer-events: none;
    opacity: 0.3;
}
